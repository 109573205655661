import React from 'react';
import './section.scss';

import useAnimateOpacity from '../../hooks/useAnimateOpacity';

export default function Section({
    id,
    index,
    title,
    children
}) {

    const elemRef = useAnimateOpacity(400);

    return (
        <section ref={elemRef} id={id} className='section'>
            <div className="section--content">
                <span className="section--content--index">{index}</span>
                <h2 className="section--content--title">
                    <span className="section--content--title--before">
                        Как мы этого достигаем:
                    </span>
                    {title}
                </h2>
                <p className="section--content--text">{children}</p>
            </div>
        </section>
    )
}