import React from 'react';
import './intro.scss';

import useAnimateOpacity from '../../hooks/useAnimateOpacity';

import logoPC from '../../assets/images/logo_pc.svg';
import logoMobile from '../../assets/images/logo_m.svg';

export default function Intro() {

    const elemRef = useAnimateOpacity(400);

    function scrollToBottom() {
        window.scrollTo(0, document.documentElement.scrollHeight);
    }

    return (
        <section ref={elemRef} className="intro">
            <div className="intro--title">
                <img src={logoPC} alt=""/>
                <h1>EXPERIENCE REALITY</h1>
            </div>

            <div className="intro--content">
                <h2>VR симуляторы с новым уровнем погружения.</h2>
                <p>
                    Есть случаи, когда обучение <br className="pc_hide"/>
                    определённым навыкам на практике <br/>
                    является дорогим, неудобным или <br className="pc_hide"/>
                    небезопасным.
                    <br/><br/>
                    Наша цель достоверно воссоздать <br className="pc_hide"/>
                    условия приобретения таких <br className="m_hide"/>
                    навыков <br className="pc_hide"/>
                    с помощью технологий <br className="pc_hide"/>
                     виртуальной реальности.
                </p>

                <button onClick={scrollToBottom}>Контакты</button>
            </div>
        </section>
    );
}