import React from 'react';
import './animation.scss';

import PlatformContext from '../../context/platform';
import LoadingContext from '../../context/loading';

export default function Animation({ firstFrame = 0, totalFrames = 0 }) {
    const platform = React.useContext(PlatformContext);
    const {status, setStatus} = React.useContext(LoadingContext);
    const [frames, setFrames] = React.useState([]);
    const [currFrame, setCurrFrame] = React.useState(0);

    function fetchImages() {
        setStatus('loading');

        let promises = [];

        for ( let i = 0; i < totalFrames - 1; i++ ) {
            promises.push(fetch(`static/animation-${platform}/${i.toString().padStart(3, '0')}.jpg`));
        }

        Promise.all(promises)
            .then(result => Promise.all(result.map(item => item.blob())))
            .then(blobs => {
                setStatus('success');
                setFrames(blobs.map(blob => URL.createObjectURL(blob)));
            })
            .catch(err => {
                setStatus('failed');
                console.error(err);
            });
    }

    function calculateCurrFrame() {
        const scrollPos = document.documentElement.scrollTop;
        const scrollLength = document.documentElement.scrollHeight - window.innerHeight;
        const scrollProg = scrollPos / scrollLength;

        const currFrame = Math.min(totalFrames - 2, Math.floor(scrollProg * (totalFrames - firstFrame) + firstFrame ));
        
        setCurrFrame(currFrame);
    }

    React.useEffect(() => setCurrFrame(firstFrame), [firstFrame]);
    React.useEffect(() => { platform && fetchImages(); }, [platform]);
    React.useEffect(() => {
        if ( status !== 'success' ) return;
        window.addEventListener('scroll', calculateCurrFrame);
        return () => window.removeEventListener('scroll', calculateCurrFrame);
    }, [status]);

    return (
        <div id="animation" className="animation">
            { frames.map((frame, i) => <img key={i} src={frame} style={{display: i === currFrame ? 'block' : 'none' }}/>) }
        </div>
    );
}