import React from 'react';
import './contacts.scss';

import useAnimateOpacity from '../../hooks/useAnimateOpacity';

export default function Contacts({mail, tel}) {

    const elemRef = useAnimateOpacity(400, 'inside');

    return (
        <section id="contacts" className="contacts">
            <div ref={elemRef} className="contacts--content">
                <div className="contacts--block contacts--block_0">
                    <h2>На этом пока всё</h2>

                    <div className="contacts--block--content">
                        <p>
                            Сайт пока находится  в разработке, <br/>
                            но всё есть в нашей презентации!
                        </p>
                    </div>
                </div>

                <div className="contacts--block contacts--block_1">
                    <h2>Контакты</h2>
                    <div className="contacts--block--content">
                        { mail && <a href={`mailto:${mail}`}>{mail}</a> }
                        { tel && <a href={`tel:${tel}`}>{tel}</a> }
                    </div>
                </div>
            </div>
        </section>
    );
}