import React from 'react';

export default function usePlatform() {
  const [platform, setPlatform] = React.useState(null);

    function determinePlatform() {
        if ( !document.documentElement.clientWidth ) return;
        
        const height = window.innerHeight
        document.documentElement.style.setProperty('--vh', height + 'px');

        if ( document.documentElement.clientWidth >= 1024 ) setPlatform('pc');
        else setPlatform('mobile');
    }

    React.useEffect(() => {
        determinePlatform();
        window.addEventListener('resize', determinePlatform);
        return () => window.removeEventListener('resize', determinePlatform);
    }, []);

    return platform;
}