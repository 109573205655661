import React from 'react';
import './loader.scss';

export default function Loader() {
    return (
        <div className="loader_container">
            <svg className="loader_container--loader" viewBox="0 0 120 120">
                <circle className="loader_container--loader--circle" r="55" cx="60" cy="60"/>
            </svg>
        </div>
    );
}