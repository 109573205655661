import React from 'react';

export default function useAnimateOpacity(duration, vpPos = 'outside') {
    const elemRef = React.useRef();
    const [visible, setVisible] = React.useState(true);

    function checkVisibility() {
        const rect = elemRef.current.getBoundingClientRect();
        const visible = vpPos === 'outside' ? (rect.top <= 0 && rect.bottom >= window.innerHeight) : (rect.top >= 0 && rect.bottom <= window.innerHeight);
        setVisible(visible);
    }

    function animate() {
        if ( !elemRef.current ) return;

        elemRef.current.style.transition = `${duration}ms`;
        elemRef.current.style.opacity = visible ? '1' : '0';
    }

    React.useEffect(() => {
        window.addEventListener('scroll', checkVisibility);
        return () => window.removeEventListener('scroll', checkVisibility);
    }, []);

    React.useEffect(animate, [visible]);

    return elemRef;
}