import React from 'react';

import '../assets/scss/fonts.scss';
import '../assets/scss/default.scss';

import usePlarform from '../hooks/usePlatform';

import PlatformContext from '../context/platform';
import LoadingContext from '../context/loading';

import Intro from '../components/Intro/Intro';
import Section from '../components/Section/Section';
import Contacts from '../components/Contacts/Contacts';
import Animation from '../components/Animation/Animation';

import Loader from '../components/Loader/Loader';

export default function TitlePage() {

    const platform = usePlarform();
    const [status, setStatus] = React.useState('idle');

    React.useEffect(() => {
        if ( !document ) return;
        document.body.style.overflowY = status === 'success' ? 'scroll' : 'hidden';
        console.log(status);
    }, [status]);

    return (
        <PlatformContext.Provider value={platform}>
        <LoadingContext.Provider value={{ status, setStatus }}>
        { status !== 'success' && <Loader /> }
        <main>
            <Animation firstFrame={60} totalFrames={300}/>

            <Intro/>
                
            <Section index={1} title={"Аналогичное оборудование"}>
                Для полноценного эффекта погружения <br className="pc_hide"/>
                мы создаём элементы <br className="m_hide"/>
                управления, <br className="pc_hide"/>
                аналогичные тем, с которыми обучаемый <br className="pc_hide"/>
                будет <br className="m_hide"/>
                взаимодействовать в реальности.
            </Section>

            <Section index={2} title={"VR-Перефирия"}>
                Шлем виртуальной реальности и трекинг <br className="pc_hide"/>
                рук позволяют <br className="m_hide"/>
                обучаемому погрузиться в <br className="pc_hide"/>
                обстановку, по ощущениям <br/>
                приближенную к реальной.
            </Section>

            <Section index={3} title={"Точная симуляция"}>
                Программа сценариев с реалистично <br className="pc_hide"/>
                проработанной <br className="m_hide"/>
                физикой, позволяющая <br className="pc_hide"/>
                отрабатывать необходимые <br className="m_hide"/>
                навыки как в <br className="pc_hide"/>
                стандартных, так и в экстремальных <br/>
                условиях, избегая рисков.
            </Section>

            <Contacts mail="info@wsl.group" />
        </main>
        </LoadingContext.Provider>
        </PlatformContext.Provider>
    );
}